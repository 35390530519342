<template>
  <div>
    <p class="title">
      <span>General</span>
      <button
        class="button"
        title="Copy table to clipboard"
        style="float:right; font-size:15px"
        v-on:click="copyToClipboardWithHide('GeneralTable')"
      >
        <span class="icon is-small">
          <i class="fas fa-copy"></i>
        </span>
      </button>
    </p>

    <!-- <div>
      Customer service contacts
      title name
    </div>-->

    <table class="is-size-8" id="GeneralTable">
      <tr>
        <th>Name</th>
        <td>{{fulfilment.Data.primary_contact.primary_contact_title}} {{fulfilment.Data.primary_contact.primary_contact_first_name}} {{fulfilment.Data.primary_contact.primary_contact_last_name}}</td>
      </tr>
      <tr>
        <th>Email</th>
        <td>{{fulfilment.Data.primary_contact.primary_contact_email}}</td>
      </tr>
      <tr>
        <th>Billing</th>
        <td>
          <span
            class="icon is-small"
            title="Copy to clipboard"
            v-if="!hideForCopy"
            style="float:right; font-size:15px; cursor:pointer"
            v-on:click="copyToClipboardWithHide('GeneralBillingAddress')"
          >
            <i class="fas fa-copy"></i>
          </span>
          <span
            id="GeneralBillingAddress"
            v-html="addressAsString(this.fulfilment.Data.billing_contact, 'billing_contact')"
          ></span>
        </td>
      </tr>
      <tr>
        <th>Delivery</th>
        <td>
          <span
            class="icon is-small"
            title="Copy to clipboard"
            v-if="!hideForCopy"
            style="float:right; font-size:15px; cursor:pointer"
            v-on:click="copyToClipboardWithHide('GeneralRecipientAddreses')"
          >
            <i class="fas fa-copy"></i>
          </span>
          <span
            id="GeneralRecipientAddreses"
            v-html="addressAsString(this.fulfilment.Data.recipient_contacts, 'recipient_contact')"
          ></span>
        </td>
      </tr>
      <tr>
        <th>Product</th>
        <td>{{fulfilment.ProductName}}</td>
      </tr>
      <tr>
        <th>Shortsite</th>
        <td>{{fulfilment.Data.products_info.campaign_ref}}</td>
      </tr>
      <tr>
        <th title="Subscription Type">Sub type</th>
        <td>{{fulfilment.Data.products_info.offer_misc.offer_type}}</td>
      </tr>
      <tr>
        <th>Date/Time</th>
        <td>{{fulfilment.Data.meta_info.transaction_datetime}}</td>
      </tr>
      <tr>
        <th title="Magazine.co.uk (magento) Order Id">JMAG Id</th>
        <td>{{fulfilment.Data.meta_info.order_id}}</td>
      </tr>
      <tr>
        <th title="Transaction Reference">Trans Ref</th>
        <td>{{fulfilment.Data.meta_info.transaction_ref}}</td>
      </tr>
      <tr>
        <th>Xmas Gift</th>
        <td>
          <span
            v-if="fulfilment.Data.products_info.offer_misc.xmasgift"
          >{{fulfilment.Data.products_info.offer_misc.xmasgift}}
            <span title="Xmas Code shown here is the CURRENT code assigned to that order, for historical orders value here may not be the value at time of order" v-if="fulfilment.XmasValue">(XMAS CODE: `{{fulfilment.XmasValue}}`)</span>
          </span>
          <span v-else>No</span>
        </td>
      </tr>
      <tr>
        <th>Discount Code</th>
        <td>{{fulfilment.Data.other.discount_code}}</td>
      </tr>
      <tr>
        <th>Voucher Code</th>
        <td>{{fulfilment.Data.other.voucher_code}}</td>
      </tr>
      <tr>
        <th>Free Gift</th>
        <td>
          <span
            v-if="fulfilment.Data.products_info.free_gift"
          >{{fulfilment.Data.products_info.free_gift}}</span>
          <span v-else-if="shouldHaveGiftOrDigtialAccessFields">None</span>
          <span v-else>Unknown</span>
        </td>
      </tr>
      <tr>
        <th>Digital Access</th>
        <td class="wba">
          <span
            v-if="fulfilment.Data.products_info.free_digital_access"
          >{{fulfilment.Data.products_info.free_digital_access}}</span>
          <span v-else-if="shouldHaveGiftOrDigtialAccessFields">None</span>
          <span v-else>Unknown</span>
        </td>
      </tr>

      <tr>
        <th class="wsn">Batch Status</th>
        <td class="wba">
          <span v-if="fulfilment.BatchJobId">
            ID: {{fulfilment.BatchJobId}}
            <span
              v-if="fulfilment.BatchJobInfo"
            >&nbsp;- {{fulfilment.BatchJobInfo.StatusAsString }} at {{fulfilment.BatchJobInfo.RunAt}}</span>
          </span>
          <span v-else>Not Sent</span>
          <div
            v-if="fulfilment.BatchJobId && fulfilment.BatchJobInfo && fulfilment.BatchJobInfo.SendLogMessage"
          >
            <b>Log:&nbsp;</b>
            <span>{{fulfilment.BatchJobInfo.SendLogMessage}}</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { baseMixin } from "../../../mixins/baseMixin.js";
export default {
  name: "orderGeneralInfo",
  props: {
    fulfilment: null
  },
  data: function() {
    return {
      hideForCopy: null
    };
  },
  mixins: [baseMixin],
  methods: {
    copyToClipboardWithHide(value) {
      this.hideForCopy = true;
      this.copyToClipboard(value);
      this.hideForCopy = false;
    }
  },
  computed: {
    shouldHaveGiftOrDigtialAccessFields() {
      //if not jmag (mini site whos have it, or is jmag and after this date)
      return (
        this.fulfilment.Data.meta_info.campaign_ref == "JMAG" ||
        new Date(this.fulfilment.CreatedAt) > new Date(2020, 4, 29, 11, 45, 0)
      );
    }
  }
};
</script>
